import React from "react";
import Image from "next/image";

interface Error404Props {
  className?: string;
  style?: React.CSSProperties;
}

const Error404 = ({
  className,
  style = { height: "100vh", width: "100vw" },
}: Error404Props) => {
  return (
    <div className={`${className}`}>
      <div
        className={`flex flex-column justify-center items-center tc`}
        style={style}
      >
        <Image
          src="/images/not_found_image.png"
          alt="Page Not Found"
          width={500}
          height={300}
        />
        <h1>Page Not Found</h1>
        <p>This page seems to have disappeared.</p>
      </div>
    </div>
  );
};

export default Error404;
